.gift-cards-page {
  // max-width: 720px;
  margin: 0 auto;
  padding: 1em 0.5em;
  // text-align: center;
  .submit {
    background: #1662f9;
    color: white;
    width: 100%;
    margin-top: 0.5em;
    &:hover {
      cursor: pointer;
    }
  }
  h1 {
    text-align: center;
    font-size: 1.5em;
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    svg {
      width: 500px;
      max-width: 100%;
    }
    form {
      width: 100%;
      // max-width: 500px;
      @media screen and (min-width:640px) {
        width: 320px;
        padding: 0 0.5em;
      }
    }
  }
  h1 {
    font-weight: 600;
    padding: 1em 0;
  }
  textarea, input {
    box-sizing: border-box;
    border: 1px solid black;
    max-width: 100%;
  }
  .input-group {
    margin: 0.5em 0;
    label {
      margin-bottom: 0.2em;
    }
  }
  .status {
    text-align: center;
    padding: 1em;
    max-width: 450px;
    margin: 0 auto;
    margin-bottom: 1em;
    border-radius: 0.3em;
    &.success {
      background: #96fdc0;

    }
    &.failure {
      background: #e3e3e3;

    }
  }
}

.gift-card-options {
  display: flex;
  flex-wrap: wrap;
  // justify-content: center;

  .option {
    // flex: -1;
    // display: block;
    width: 100px;
    margin: 0.2em;
    border-radius: 0.2em;
    height: 80px;
    border: 1px solid black;
    &.picked {
      color: white;
      background: #175adc;
    }
    label {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    input[type="radio"] {
      clip-path: inset(100%);
      clip: rect(1px 1px 1px 1px); /* IE 6/7 */
      clip: rect(1px, 1px, 1px, 1px);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap; /* added line */
      width: 1px;
    }
  }
}
.gift-card-custom-option {
  margin-top: 0.5em;
  // display: flex;
  // flex-wrap: wrap;
  // align-items: baseline;
  // justify-content: center;
  label {
    margin-right: 0.3em;
    font-size: 0.9em;
    margin-bottom: 0.3em;
  }
  input {
    width: 4em;
    border: 1px solid black;

  }
}
